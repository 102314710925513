// 产品中心

<template>
  <div class="product">
    产品中心
    
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.product {
  min-height: 500px;
}
</style>